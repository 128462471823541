<!--  -->
<template>
  <div class="frame">
    <top @routerGo="$router.push('/')"></top>
  </div>
</template>

<script>
import top from "../components/top.vue";
export default {
  components: { top },
  data() {
    return {};
  },
  created() {
    this.$toast.fail({ message: "请在微信浏览器中打开", duration: 5000 });
  },
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.frame {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/bg.png");
  background-size: 100% 100%;
}
</style>
